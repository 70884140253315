import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError
} from "@remix-run/react";
import { json, LoaderFunctionArgs, MetaFunction } from "@remix-run/cloudflare";
import "./tailwind.css";
import React, { useEffect } from "react";
import { load, trackPageview } from "fathom-client";
import { existsConfig } from "@/lib/config.server";
import { ExternalScripts } from "remix-utils/external-scripts";
import { Toaster } from "@/components/ui/sonner";
import { getAuthenticatedUser } from "@/lib/utils.server";

// export const links: LinksFunction = () => [
//   { rel: "stylesheet", href: tailwindStylesheetUrl },
// ];

export const meta: MetaFunction = () => {
  return [{
    title: "Rank-Hub - SEO in Easy-Mode"
  },{
    name: "description",
    content: "Never wonder what to write with Rank-Hub"
  },{
    property: "og:image",
    content: "https://rank-hub.com/og-image.jpg"
  },{
    property: "og:url",
    content: "https://rank-hub.com"
  },{
    property: "og:type",
    content: "website"
  },{
    property: "og:title",
    content: "Rank-Hub - SEO in Easy-Mode"
  },{
    property: "og:description",
    content: "Never wonder what to write with Rank-Hub",
  },{
    name: "twitter:card",
    content: "summary_large_image",
  },{
    property: "twitter:domain",
    content: "rank-hub.com",
  },{
    property: "twitter:url",
    content: "https://rank-hub.com",
  },{
    name: "twitter:title",
    content: "Rank-Hub - SEO in Easy-Mode",
  },{
    name: "twitter:description",
    content: "Never wonder what to write with Rank-Hub",
  },{
    name: "twitter:image",
    content: "https://rank-hub.com/og-image.jpg",
  },
  ];
};

export const loader = async ({request, context}: LoaderFunctionArgs)=>{
  const user = await getAuthenticatedUser(request);

  return json({
    user,
    DEV: existsConfig(context, "DEV")
  });
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const data = useLoaderData<typeof loader>();
  // Get the locale from the loader
  const location = useLocation();

  useEffect(() => {
    if (!data.DEV) load("OSNNIJUU", {
      url: "https://cdn.usefathom.com/script.js"
    });
  }, []);

  useEffect(() => {
    if (!data.DEV) trackPageview();
  }, [location.pathname, location.search]);

  return (
    <html lang="en">
    <head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <Meta />
      <Links />
    </head>
    <body className="text-gray-700 bg-gray-50">
    <Outlet />
    <ScrollRestoration />
    <Scripts />
    <ExternalScripts />
    <Toaster toastOptions={{
      duration: 5000
    }} />
    </body>
    </html>
  );
}
